
.custom-tabs {
  color: #333!important;
   font-size: smaller;
  }
  
  .custom-tab {
    padding: 20px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .custom-tab h2 {
 color: #333;
  }
  .custom-tabs .nav-link{
    border: none;
    color: #333;
  }
  .custom-tabs .nav-link.active {
    border-bottom: 2px solid #007bff; /* Set bottom border for the active tab */
    color: #007bff;
    font-weight: bolder;
  }

  .main-content {
    padding-top: 20px;
  }

  /* DashBoard.css */

/* Overall dashboard container */
.D-container {
    display: flex;
    gap: 0;
    font-family: Arial, sans-serif;
  }
  /* SideBar.css */
  .profile {
    display: flex;
    justify-content:flex-end;
    gap: 20px;
    align-items: center;
    padding: 20px;
    width: 100%;
  }
  
  .profile-image img {
    width: 50px; /* Adjust according to your preference */
    height: 50px; /* Adjust according to your preference */
    border-radius: 50%;
  }
  
  .logout-btn {
    padding: 10px 20px;
    background-color: #475be1; /* Example logout button color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  /* Sidebar styles */
.D-sidebar {
  width: 200px;
  color: #f8f8f8;
  height: 100vh;
  border-radius: 8px;
  position: sticky;
  top: 0; /* Fixing the sidebar to the top */
  overflow-y: hidden; /* Hide overflowing content */
}

.D-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.D-sidebar ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 0.9rem;
  display: block;
  padding: 8px 0;
}

.D-sidebar ul li a:hover {
  border-radius: 5px;
  color: #444141;
}

/* Add gap between links */
.D-sidebar ul li:not(:last-child) {
  margin-bottom: 1px;
}

  /* Dashboard content styles */
  .D-content {
    flex: 1;
    padding: 20px;
    border-radius: 8px;
    overflow-y: auto; /* Enable vertical scrolling for content */
  }
  
  .D-content h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  
  /* Responsive adjustments for small devices */
  @media screen and (max-width: 768px) {
    /* Adjustments for responsive layout */
  }
  
  /* Responsive adjustments for small devices */
  @media screen and (max-width: 768px) {
    .D-container {
      flex-direction: column;
      padding: 10px;
    }
  
    .D-sidebar {
      width: 100%;
      margin-bottom: 20px;
      
    }
  
    .D-sidebar ul {
      display:flex;
      flex-wrap: nowrap;
      padding: 0;
      margin: 0;
      overflow-x: auto;
    }
  
    .D-sidebar ul li {
      margin-right: 10px;
      margin-bottom: 0;
    }
   
    .D-sidebar ul li:last-child {
      margin-right: 0;
    }
  
    .D-sidebar ul li a:hover {
      color: #333;
      font-size: 0.9rem;
      font-weight: bolder;
      border-bottom: #333 0.2px solid;
      background-color: transparent;
      border-radius: 5px;
    }
    .D-sidebar ul li a {
      font-size: 1.3rem;
      padding: 10px;
      transition: all ;
    }
  
    .D-content {
      margin-left: 0;
    }
  
    /* Adjust styles for mobile */
    .D-sidebar {
      padding: 10px;
      height: auto;
    }
  
    .D-sidebar h2 {
      margin-bottom: 10px;
    }
  
    .D-sidebar ul li a {
      font-size: 0.8rem;
      padding: 6px 0;
    }
  
    .D-content {
      padding: 15px;
    }
  
    .D-content h2 {
      font-size: 1.3rem;
      margin-bottom: 15px;
    }
  }
  /* Leads section css*/
  
  .user-table-container {
    margin: 20px;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-table th, .user-table td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .user-table th {
    background-color: #f2f2f2;
  }
  
  .user-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .user-table tbody tr:hover {
    background-color: #ddd;
  }
  