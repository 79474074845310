.documents-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.documents-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.documents-list {
  list-style: none;
  padding: 0;
}

.document-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.download-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.download-button:hover {
  background-color: #0056b3;
}

.download-button:active {
  transform: translateY(1px);
}
