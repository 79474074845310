.scrollable-table-container {
  overflow-x: auto;
  max-height: 470px;
  overflow-y: auto;
  position: relative;
  font-size:0.7rem;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 2;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.modal-dialog {
  max-width: 900px; /* Adjust the width as per your requirement */

}

/* Style for Update Data section */
.update-data-section {
  margin-bottom: 20px;
  max-height: 400px;
  overflow-y: auto;
}

.update-data-section b {
  font-size: 1.4em;
}

.update-data-section hr {
  margin-top: 5px;
}

/* Style for input fields */
.input-container {
  margin-bottom: 10px;
}

.input-container span {
  margin-right: 10px;
  width:200px;
}

.input-container input,
.input-container select {
  width: 220px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;

}

/* Style for Personal Profile section */
.personal-profile-section b {
  font-size: .8em;
}

.personal-profile-section span {
  margin-right: 10px;
}


th,
td {
  border-bottom: 0.3px solid #cacaca;
  text-align: center;
}
.fixed-top{
  position: sticky;
  background-color: #ecf0f5; 
  z-index: 1;
}
.fixed-top:nth-child(3){
  font-weight: bolder;
  z-index: 3;
}
.fixed-top:nth-child(2){
  font-weight: bolder;
  z-index: 3;
}

.fixed-top:nth-child(4){
  font-weight: bolder;
  z-index: 3;
}
.scrollable {
  overflow-x: auto;
  min-width: 150px; /* Adjust as needed */
}

.non-scrollable {
  position: sticky;
  background-color: #ecf0f5; /* Optional: Set background color for fixed columns */
  z-index: 2; /* Optional: Ensure fixed columns appear above other cells */
}

/* Adjust left position for each non-scrollable column */
.non-scrollable:nth-child(1) {
  left: 1px;
  position: sticky;
  background-color: #ecf0f5; /* Optional: Set background color for fixed columns */
  z-index: 2; /* Optional: Ensure fixed columns appear above other cells */
  min-width: 80px;
}

.non-scrollable:nth-child(2) {
  left: 130px;

  background-color: red;
}

.non-scrollable:nth-child(3) {
  left: 245px; 
  min-width:120px;

}

.non-scrollable:nth-child(4) {
  left: 392px; 

}
