.admin-file-upload-container {
    max-width: 300px;
  
    padding: 20px;
    border-radius: 10px;
    background-color: #fcfcfccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .file-input-container {
    margin-bottom: 20px;
  }
  
  .file-label {
    display: block;
    margin-top: 10px;
    font-size: 18px;
    color: #333;
  }
  
  .file-input {
    display: none;
  }
  
  .upload-button {
    padding: 12px 24px;
    background-color: #475be1;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  .inputfile{
    border: none;
    color: #0056b3;
  }
  .upload-button:hover {
    background-color: #0056b3;
  }
  
  .selected-file {
    margin-top: 10px;
    font-size: 16px;
    color: #555;
  }
  