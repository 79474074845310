/* DashBoard.css */

/* Overall dashboard container */
.D-container {
  display: flex;
  gap: 0;
  font-family: Arial, sans-serif;
}
/* SideBar.css */
.profile {
  display: flex;
  justify-content:flex-end;
  gap: 20px;
  align-items: center;
  padding: 20px;
  width: 100%;
}

.profile-image img {
  width: 50px; /* Adjust according to your preference */
  height: 50px; /* Adjust according to your preference */
  border-radius: 50%;
}

.logout-btn {
  padding: 10px 20px;
  background-color: #475be1; /* Example logout button color */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Sidebar styles */
.D-sidebar {
  width: 250px;
  height: 100vh;
  padding:50px 8px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(221, 219, 219, 0.903);
  background-color: #fff;
  position: sticky;
  top: 0; /* Fixing the sidebar to the top */
  overflow-y: auto; /* Enable vertical scrolling if needed */
 
}


.D-sidebar h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.D-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.D-sidebar ul li {
 margin: 40px 25px;
}

.D-sidebar ul li a {
  text-decoration: none;
  color: #333;
  font-size: 1.1rem;
  display: block;
  padding: 8px 0;
}

.D-sidebar ul li a:hover {
  border-radius: 5px;
}

/* Add gap between links */
.D-sidebar ul li:not(:last-child) {
  margin-bottom: 15px;
}

/* Dashboard content styles */
.D-content {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  overflow-y: auto; /* Enable vertical scrolling for content */
}

.D-content h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

/* Responsive adjustments for small devices */
@media screen and (max-width: 768px) {
  /* Adjustments for responsive layout */
}

/* Responsive adjustments for small devices */
@media screen and (max-width: 768px) {
  .D-container {
    flex-direction: column;
    padding: 10px;
  }

  .D-sidebar {
    width: 100%;
    margin-bottom: 20px;
    
  }

  .D-sidebar ul {
    display:flex;
    flex-wrap: nowrap;
    padding: 0;
    margin: 0;
    overflow-x: auto;
  }

  .D-sidebar ul li {
    margin-right: 10px;
    margin-bottom: 0;
  }
 
  .D-sidebar ul li:last-child {
    margin-right: 0;
  }

  .D-sidebar ul li a:hover {
    color: #333;
    font-size: 0.9rem;
    font-weight: bolder;
    border-bottom: #333 0.2px solid;
    background-color: transparent;
    border-radius: 5px;
  }
  .D-sidebar ul li a {
    font-size: 1.3rem;
    padding: 10px;
    transition: all ;
  }

  .D-content {
    margin-left: 0;
  }

  /* Adjust styles for mobile */
  .D-sidebar {
    padding: 10px;
    height: auto;
  }

  .D-sidebar h2 {
    margin-bottom: 10px;
  }

  .D-sidebar ul li a {
    font-size: 0.8rem;
    padding: 6px 0;
  }

  .D-content {
    padding: 15px;
  }

  .D-content h2 {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
}
/* Custom styles for OtherIdeas component */
