/* .mainContainer {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .titleContainer {
    display: flex;
    flex-direction: column;
    font-size: 64px;
    font-weight: bolder;
    align-items: center;
    justify-content: center;
  }
  
  .resultContainer,
  .historyItem {
    flex-direction: row;
    display: flex;
    width: 400px;
    align-items: center;
    justify-content: space-between;
  }
  
  .historyContainer {
    flex-direction: column;
    display: flex;
    height: 200px;
    align-items: center;
    flex-grow: 5;
    justify-content: flex-start;
  }
  
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 260px;
  }
  
  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .inputContainer > .errorLabel {
    color: red;
    font-size: 12px;
  }
  
  .inputBox {
    height: 48px;
    width: 400px;
    font-size: large;
    border-radius: 8px;
    border: 1px solid grey;
    padding-left: 8px;
  } */

  
:root{
  --white-colour : whitesmoke ;
  --purple-colour : purple ;
  --grey-colour : grey ;
}
.login-container{
  display: flex;
}

.box {
  width: 50%;
  height: 100vh;
  position: relative;
  background-color: var(--white-colour);
}

/* Left Side Styling */
.background-image-div {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .9;
  background: linear-gradient(to bottom right,var(--grey-colour), var(--purple-colour)); /* Adjust opacity here */
}

.background-image-div img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.context {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  border-radius: 10px;
  height: 80%;
  width: 80%; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 4rem;
}

.logo h1 {
  font-weight: bolder;
  color: var(--white-colour);
  font-size: 3rem;
  margin-bottom: 1rem;
  /* animations */
  opacity: 0;
  animation: slideFromLeft 1s forwards;
}

.logo p {
  color: var(--white-colour);
  font-size: 1.2rem;
  font-weight: 500;
  /* animation */
  opacity: 0;
  animation: slideFromTop 1s forwards;
}

.icons-div {
  display: flex;
  justify-content: center;
}

.icon {
  height: 2rem;
  width: 2rem;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  border: .1rem solid var(--white-colour);
  color:var(--white-colour);
  margin-right: .5rem;
  font-weight: 600; 
  /* Animations */
  transition: all 0.7s ease; 
  opacity: 0;
  animation: slideLeft 1s ease forwards;
}

.icon:hover {
  cursor: pointer;
  color: var(--purple-colour);
  background-color: var(--white-colour);
  border: .1rem solid var(--purple-colour);
}

/* Right Side Styling */
.right{
  display: flex;
  align-items: center;
  justify-content: center;
}
.form{
  height: 50%;
  width: 70%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form h3{
  color: var(--purple-colour);
  font-size: 1.2rem;
}

.form .input{
  width: 100%;
  border: none;
  padding: .2rem;
  padding-left: 1rem;
  border-radius: .2rem;
  line-height: 2.5rem;
  border: none;
  outline: none;
  box-shadow: 2px 2px 4px 4px hsl(0, 0%, 90%);
}
.form .input:focus{
  border-bottom: .2rem solid var(--purple-colour);
}
.form label{
  color: var(--purple-colour);
  font-weight: 500;
}
.preferences{
  max-width: 100%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  padding: .1rem;
  gap: .5rem;
}
.preferences label{
  width: 50%;
  display: flex;
  flex-direction: column;
}

select {
  outline: none;
  border: none;
  appearance: none; 
  padding: 10px;
  font-size: 16px;
  color: var(--grey-colour);
  border-radius: 5px;
  width: 100%;
  box-shadow: 2px 2px 4px 4px hsl(0, 0%, 90%);
}

select option {
  background-color: var(--white-colour);
  color: var(--grey-colour);
}

select option:checked {
  background-color: var(--purple-colour);
  color: var(--white-colour);
}

.login-button {
  background-color: var(--purple-colour); 
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s; 
}

.login-button:hover {
  transform: scale(.96);
  background-color: green;
}

/* Keyframes of all animation identifiers */
@keyframes slideFromLeft {
  from{
      transform: translateX(-100%);
      opacity: 0;
  }
  to{
      transform: translateX(0);
      opacity: 1;
  }
  
}
@keyframes slideFromTop {
  from{
      transform: translateY(-100%);
      opacity: 0;
  }
  to{
      transform: translateY(0);
      opacity: 1;
  }
}
@keyframes slideLeft {
  from{
      transform: translateX(100%);
      opacity: 0;
  }
  to{
      transform: translateX(0);
      opacity: 1;
  }
}