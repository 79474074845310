.custom-card {
   border: none;
   height: 120px;
   width: 200px;
   text-align: center;
   color: #5a5656;
  }
  
  .custom-card-freelancers {
    background-color: #a8ff9b; /* Custom color for Freelancers card */
  }
  
  .custom-card-clients {
    background-color: #a2d7fb; /* Custom color for Clients card */
  }
  
  .custom-card-investors {
    background-color: #e7c8f4; /* Custom color for Investors card */
  }
  
  .custom-card-business-startups {
    background-color: #abeaff; /* Custom color for Business Startups card */
  }
  